<template>
  <section id="content">
    <PageTitle :title="$t('intrastats_check.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <Messages :messages="errors" type="error"></Messages>
      <Messages :messages="successes" type="success"></Messages>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content p-md">

              <form role="form" name="checkForm" @submit="onFormSubmit">
                <div v-if="isEmailEnabled" class="form-group row">
                    <label for="checkFileEmail" class="col-lg-3 col-form-label">{{ $t('intrastats_check.form.email') }}</label>
                    <div class="col-lg-9">
                      <input id="checkFileEmail" type="email" :placeholder="$t('intrastats_check.form.email_placeholder')" class="form-control" v-model="form.email" required>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="checkFileCompany" class="col-lg-3 col-form-label">{{ $t('intrastats_check.form.company') }}</label>
                    <div class="col-lg-9">
                      <div v-if="isCompanyEnabled && listCompanies.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                          <div class="sk-bounce1"></div>
                          <div class="sk-bounce2"></div>
                          <div class="sk-bounce3"></div>
                      </div>
                      <v-select v-if="isCompanyEnabled && listCompanies.length > 0" id="checkFileCompany" :placeholder="$t('intrastats_check.form.company_placeholder')" v-model="form.company" :options="listCompanies" label="name">
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!form.company"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                      <p v-if="!isCompanyEnabled" class="form-control-plaintext">{{profile.company.name}}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="generateXMLDeclarationTypeCode" class="col-lg-3 col-form-label">{{ $t('intrastats_check.form.declaration-type-code') }}</label>
                    <div class="col-lg-9">
                      <select id="generateXMLDeclarationTypeCode" class="form-control" v-model="form.declarationTypeCode" required>
                        <option value="1">{{ $t('intrastats_check.form.declaration-type-code-one') }}</option>
                        <option value="2">{{ $t('intrastats_check.form.declaration-type-code-two') }}</option>
                        <option value="3">{{ $t('intrastats_check.form.declaration-type-code-three') }}</option>
                        <option value="4">{{ $t('intrastats_check.form.declaration-type-code-four') }}</option>
                        <option value="5">{{ $t('intrastats_check.form.declaration-type-code-five') }}</option>
                      </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="checkFileFile" class="col-lg-3 col-form-label">{{ $t('intrastats_check.form.file') }}</label>
                    <div class="col-lg-9">
                      <b-form-file
                        v-model="form.file"
                        :state="Boolean(form.file)"
                        :placeholder="$t('intrastats_check.form.file_placeholder')"
                        :drop-placeholder="$t('intrastats_check.form.file_drop-placeholder')"
                        required
                      />
                    </div>
                </div>
                <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('intrastats_check.form.button') }}</button>
              </form>
            
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: {
    PageTitle,
    Messages
  }
})
export default class Check extends mixins(GenericPage) {

  // We disable the email by default as we are using the user email
  isEmailEnabled = false;
  // We disable the company by default as we are using the user company (if there is one)
  isCompanyEnabled = false || !this.profile.company;

  form = {
    email: '',
    company: this.profile.company,
    declarationTypeCode: '1',
    file: null
  };

  errors:Message[] = [];
  successes:Message[] = [];

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  get profile() {
    return this.user.profile;
  }

  laddaSubmit:Ladda.LaddaButton|null = null;
  listCompanies:any[] = [];
  
  created() {
    if(this.isCompanyEnabled) {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: this
      }
      api.getAPI('/api/intrastats/list-companies', options).then((response:any) => {
        if(response.companies) {  
          this.listCompanies = response.companies;
        }
      });
    }
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=checkForm] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onApiErrors(errors:Message[]) {
    this.errors = errors;
  }

  onApiSuccesses(successes:Message[]) {
    this.successes = successes
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var formData = new FormData();
    if(this.isEmailEnabled) {
      formData.append("email", this.form.email);
    }
    if(this.isCompanyEnabled) {
      formData.append("companyID", this.form.company._id);
    }
    formData.append("declarationTypeCode", this.form.declarationTypeCode);

    if(this.form.file != null) {
      var size = (this.form.file! as File).size;
      if(size > 1000000) {
        this.$store.commit('messages/' + messagesTypes.mutations.ADD_GENERIC_MESSAGE, {
                message: this.$t('intrastats_check.errors.fileToBig'),
                type: MessageTypes.ERROR
            });
        return;
      }
      formData.append("file", this.form.file!, (this.form.file! as File).name);  
    }

    var options:api.ApiVueOptions =  {
      app: this,
      errorsHandler: this.onApiErrors,
      successesHandler: this.onApiSuccesses
    }
    
    // We clear the current messages
    this.errors = [];
    this.successes = [];

    this.laddaSubmit!.start();

    api.postAPIFormData('/api/intrastats/check', formData, options).then((response:any) => {
      if(response.checked) {
        this.form.file = null;
      }
      this.laddaSubmit!.stop();
    });
  }
}
</script>